<template>
		<div v-if="isOnline" class="loggedin-header d-flex">
			<div class="row mx-0 my-auto w-100">
				<div class="col-8 px-0">
					<p class="secondary-display xl t-secondary mb-0">{{ full_name }}</p>
					<a
						href="/account/details"
						class="p xxsm apercu-medium t-secondary t-uppercase let-spacing-1 mb-0"
						>my account ></a
					>
				</div>
				<div class="col-4 px-0 d-flex">
					<img
						class="ml-auto"
						src="/assets/icons/orange/user-account.svg"
						alt="user account navbar avatar"
					/>
				</div>
			</div>
		</div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
// Props (defineProps)
// Emits (defineEmits)
// Reactive variables (inc composables)
  const store = useStore()
// Computed
	const isOnline = computed(() => store.getters['user/isOnline'])
	const full_name = computed(() => store.getters['user/full_name'])
// Methods
// Watchers
// Lifecycle Hooks
// Expose (defineExpose)
</script>

<style lang="scss" scoped>
</style>
